import { Injectable } from '@angular/core';
/**
 * Constant definitions for the application
 *
 */
@Injectable()
export class ApiControllers {

    public readonly sampleController: string = 'evacuate2';

}
