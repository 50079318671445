<p-blockUI [blocked]="blocked" [target]="pcard">
    <i class="fas fa-plug" style="color: red; font-size: 3rem"></i>
</p-blockUI>

<p-card #pcard>
    <h4><i class="fas fa-user-ninja"></i> Start & Stop Evacuations</h4>
    <p-table [value]="buildings$ | async">
        <ng-template pTemplate="body" let-building>
            <tr>
                <td>{{building.name}}</td>
                <td>
                    <ng-container *ngIf="findActiveEvacuations(building) as activeEvacuations; else notActive">
                        <ng-container *ngFor="let evac of activeEvacuations">
                            <div class="evac-mng-buttons">
                            <button pButton type="button" class="p-button-danger" (click)="stopEvacuation(building, evac)" icon="far fa-stop"></button>
                            &nbsp;<button pButton type="button" class="p-button-primary" (click)="broadcast(building, evac)" icon="far fa-megaphone"></button></div>
                        </ng-container>
                    </ng-container>
                    <ng-template #notActive>
                        <button pButton type="button" class="p-button-success" (click)="startEvacuation(building)" icon="far fa-play"></button>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <pre style="color: red;" *ngIf="error">
        ERROR:
        {{ error | json }}
    </pre>
</p-card>

<p-confirmDialog key="evacAdminConfirm" [closable]="false" header="Confirmation" icon="pi pi-exclamation-triangle" acceptButtonStyleClass="p-button-danger"></p-confirmDialog>
