import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@app/environment';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Building } from '../models/building';
import { EvacEvent, Evacuation, Report, ReportEvent } from '../models/evacuation';
import { User } from '../models/user';

import { LoadingService } from './loading.service';

export interface PagedData<T> {
    pageInfo: PageInfo;
    data: T[];
    totalCount: number;
    variables: any;
}

export interface PageInfo {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export const parseJSONDates = (data: any): any => {
    const result = {};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            let value = data[key];
            if (value !== null) {
                if (key.endsWith('On') || key.endsWith('Time')) {
                    try {
                        value = new Date(value);
                    } catch (e) {
                        console.error('date parse error', value);
                    }
                } else if (Array.isArray(value)) {
                    value = value.map(x => (typeof x === 'object') ? parseJSONDates(x) : x);
                } else if (typeof value === 'object') {
                    value = parseJSONDates(value);
                }
            }
            result[key] = value;
        }
    }
    return result;
};

const STORAGE_TOKEN_KEY = 'token';
const STORAGE_USER_KEY = 'email';

const evacuationsSubscription = gql`
    subscription EvacEvent {
        subscribeEvacuations {
            author
            authorId
            message
            id
            evacuation {
                id
                buildingId
                startTime
                endTime
                building {
                    id
                    code
                    name
                }
                reportCount
            }
            status
        }
    }`;

const reportsSubscription = gql`
    subscription ReportEvent {
        subscribeReports {
            author
            authorId
            message
            id
            report {
                id
                evacuationId
                status
                zone {
                    id
                    floor
                    name
                }
                user {
                    id
                    firstName
                    lastName
                }
                comment
                createdOn
                lastmodifiedOn
            }
            status
        }
    }`;

const getEvacuationWithReportsQuery = gql`
    query GetEvacuationWithReports($evacuationId: Int!) {
        evacuation(id: $evacuationId) {
            id
            building {
                code
                name
                zones {
                    id
                    name
                    floor
                }
            }
            reports {
                id
                status
                zone {
                    id
                    floor
                    name
                }
                user {
                    id
                    firstName
                    lastName
                }
                comment
                createdOn
                lastmodifiedOn
            }
        }
    }`;

const getRecentEvacuationsQuery = gql`
    query GetRecentEvacuations($take: Int, $skip: Int) {
        evacuations(take: $take, skip: $skip, where: {endTime: {neq: null}, and: {reportCount: {gt: 0}}}, order: {startTime: DESC}) {
            totalCount
            items {
                id
                buildingId
                building {
                    code
                    name
                    zones {
                        id
                        name
                        floor
                    }
                }
                startTime
                endTime
                reportCount
                reports {
                    id
                    status
                    zone {
                        id
                        floor
                        name
                    }
                    user {
                        id
                        firstName
                        lastName
                    }
                    comment
                    createdOn
                    lastmodifiedOn  
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
        }
    }`;

const buildingsQuery = gql`
    query GetBuildings {
        buildings {
            id
            code
            name
            evacuations {
                id
                buildingId
                startTime
                endTime
            }
        }
    }
`;

const activeEvacuationsQuery = gql`
    query GetActiveEvacuations {
        evacuations(where: {endTime: {eq: null}}) {
            items {
                id
                buildingId
                startTime
                endTime
                building {
                    id
                    code
                    name
                }
                reportCount
                createdByUser {
                    id
                    firstName
                    lastName
                }
                MessagesNotRead {
                    id
                    text
                    status
                    createdOn
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

const whoAmIQuery = gql`
    query WhoAmI {
        whoAmI {
            id
            firstName
            lastName
            email
            changePassword
            zone {
                id
                name
                floor
            }
            roles {
                name
            }
        }
    }`;

const searchUsersQuery = gql`
    query SearchUsers($searchTxt: String) {
        users(where: {email: {contains: $searchTxt}}, skip: 0, take: 20) {
            items {
                id
                firstName
                lastName
                email
                zone {
                    id
                    name
                    floor
                }
                roles {
                    name
                }
            }
            totalCount
        }
    }`;

const resetPasswordMutation = gql`
    mutation ResetPassword($email: String) {
        resetPassword(input: {email: $email}) {
            email
            resetKey
        }
    }`;

const startEvacuationMutation = gql`
    mutation StartEvacuation($buildingId: Int!) {
        startEvacuation(buildingId: $buildingId) {
            evacuation {
                id
                buildingId
                building {
                    id
                    name
                }
                startTime
                endTime
            }
        }
    }`;

const stopEvacuationMutation = gql`
    mutation StopEvacuation($id: Int!) {
        stopEvacuation(id: $id) {
            evacuation {
                id
                buildingId
                building {
                    id
                    name
                }
                startTime
                endTime
            }
        }
    }`;

const createReportMutation = gql`
    mutation CreateReport($evacuationId: Int!, $userId: Int!, $zoneId: Int!, $status: ReportStatus!, $comment: String) {
        addReport(input: {evacuationId: $evacuationId, userId: $userId, zoneId: $zoneId, status: $status, comment: $comment}) {
            report {
                id
                evacuationId
                zoneId
                status
                comment
                evacuation {
                    id
                    buildingId
                }
                zone {
                    id
                    name
                    floor
                }
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }`;

const sendMessageMutation = gql`
    mutation SendMessage($evacuationId: Int!, $message: String, $status: EntityStatus!) {
        notifyEvacuationSubscribers(input: {id: $evacuationId, message: $message, status: $status})
    }`;

const markAsReadMessage = gql`
    mutation MarkAsReadMessage($messageId: Int!) {
        markAsReadMessage(messageId: $messageId)
    }`;

const sortOrderToDirection = (sortOrder: number): string => sortOrder === -1 ? 'DESC' : sortOrder === 1 ? 'ASC' : 'ASC';

const splitAtFirstDot = (input: string): [string, string] => {
    const dotIndex = input.indexOf('.');
    if (dotIndex !== -1) {
        const head = input.substr(0, dotIndex);
        const rest = input.substr(dotIndex + 1, input.length);
        return [head, rest];
    } else {
        return [input, null];
    }
};

const sortParamsToQuery = (sortField: string, sortOrder: number): string => {
    const [head, rest] = splitAtFirstDot(sortField);
    if (rest === null) {
        const direction = sortOrderToDirection(sortOrder);
        return `{${head}: ${direction}}`;
    } else {
        const query = sortParamsToQuery(rest, sortOrder);
        return `{${head}: ${query}}`;
    }
};

type SendReportSuccessCallback = (report: Report) => void;
type SendReportErrorCallback = (error: Error) => void;

export interface UserSearchInfo {
    users: User[];
    count: number;
}

export const emptyUserSearchInfo: UserSearchInfo = { users: [], count: 0 };

const parseContentDispositionHeader = (header: string): string => {
    if (header === null) {
        return null;
    }
    const prefix = 'attachment; filename=';
    if (header.startsWith(prefix)) {
        return header.substr(prefix.length);
    } else {
        return null;
    }
};

@Injectable()
export class DataProviderService implements OnDestroy {

    public online$ = new BehaviorSubject(false);
    public slow$ = new BehaviorSubject(false);

    public sendReportQueue$ = new BehaviorSubject<Report[]>([]);

    public onSendReportSuccess: SendReportSuccessCallback;
    public onSendReportError: SendReportErrorCallback;

    private subs: Subscription;

    constructor(private apollo: Apollo, private ls: LoadingService, private http: HttpClient) {
        this.subs = new Subscription();
        this.subs.add(merge(
            fromEvent(window, 'online'),
            fromEvent(window, 'offline'),
            fromEvent(window, 'focus'),
            fromEvent(window, 'blur'),
            fromEvent(window, 'visibilitychange')
        ).subscribe(event => {
            // eslint-disable-next-line no-console
            console.debug('DataProviderService - event', event);
            if (event.type === 'offline') {
                this.online$.next(false);
            }
            if (event.type === 'online') {
                this.online$.next(true);
                // eslint-disable-next-line no-console
                console.debug(`DataProviderService - back online, refetching active queries`);
                this.refetch();
            }
            if (event.type in ['focus', 'blur']) {
                // eslint-disable-next-line no-console
                console.debug(`DataProviderService - ${event.type} event`);
            }
            if (event.type === 'visibilitychange') {
                // eslint-disable-next-line no-console
                console.debug(`DataProviderService - visibility event`, window.document.visibilityState);
                if (window.document.visibilityState === 'visible') {
                    // eslint-disable-next-line no-console
                    console.debug(`DataProviderService - refetching active queries`);
                    this.refetch();
                }
            }
        }));
        this.slow$ = this.ls.slow$;
        console.debug('apollo client', apollo); // eslint-disable-line

        combineLatest([this.slow$, this.online$]).subscribe(([slow, online]) => {
            if (online && !slow) {
                this.handleSendReportQueue();
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public login(email: string, pwd: string): Observable<string> {
        const mutation = gql`
            mutation GetToken($email: String, $pwd: String) {
                token(
                    email: $email
                    password: $pwd
                )
            }`;
        const result = new Subject<string>();
        this.apollo.mutate<{ token: string }>({
            mutation,
            variables: {
                email,
                pwd,
            }
        })
            .pipe(take(1))
            .subscribe(
                ({ data }) => {
                    localStorage.setItem(STORAGE_TOKEN_KEY, data.token);
                    localStorage.setItem(STORAGE_USER_KEY, email);
                    result.next(data.token);
                    result.complete();
                },
                error => {
                    this.logout();
                    result.error(error);
                    result.complete();
                }
            );
        return result;
    }

    public changePassword(email: string, oldPassword: string, password: string): Observable<boolean> {
        const mutation = gql`
            mutation ChangePassword($email: String, $oldPassword: String, $password: String) {
                changePassword(
                    input: {
                        email: $email,
                        oldPassword: $oldPassword,
                        password: $password,
                    }
                )
            }`;
        const result = new Subject<boolean>();
        this.apollo.mutate<{ changePassword: boolean }>({
            mutation,
            variables: {
                email,
                oldPassword,
                password,
            }
        })
            .pipe(take(1))
            .subscribe(
                ({ data }) => {
                    result.next(data.changePassword);
                    result.complete();
                },
                error => {
                    result.error(error);
                    result.complete();
                }
            );
        return result;
    }

    public logout(): void {
        localStorage.removeItem(STORAGE_TOKEN_KEY);
        this.apollo.client.clearStore();
    }

    public isAuthenticated(): boolean {
        return this.getToken() !== null;
    }

    public getToken(): string {
        return localStorage.getItem(STORAGE_TOKEN_KEY);
    }

    public getLoginName(): string {
        return localStorage.getItem(STORAGE_USER_KEY);
    }

    public getAuthenticatedUser(): Observable<User> {
        const user$ = this.whoAmI();
        return user$.pipe(tap(_ => {
            this.online$.next(true);
        }));
    }

    public getBuildings(): Observable<Building[]> {
        return this.apollo.watchQuery<{ buildings: Building[] }>({ query: buildingsQuery })
            .valueChanges
            .pipe(
                map(resp => resp.data.buildings)
            );
    }

    public getWhereAmI(): Observable<string> {
        const query = gql`
            query {
                whereAmI
            }`;
        return this.apollo.query<{ whereAmI: string }>({ query })
            .pipe(
                tap(_ => {
                    this.online$.next(true);
                }),
                map(resp => resp.data.whereAmI)
            );
    }

    public getPagedEvacuations(options: { take: number; skip: number; orderField?: string; orderDirection?: number; where?: string }): Observable<PagedData<Evacuation>> {
        const variables = {
            take: options.take || null,
            skip: options.skip || null,
        };

        const orderQuery = options.orderField ? ', order: ' + sortParamsToQuery(options.orderField, options.orderDirection) : '';
        const whereQuery = options.where ? ', where: ' + options.where : '';

        const query = gql`
            query GetEvacuations($take: Int, $skip: Int) {
                evacuations(take: $take, skip: $skip${orderQuery}${whereQuery}) {
                    totalCount
                    items {
                        id
                        buildingId
                        building {
                            id
                            name
                        }
                        startTime
                        endTime
                        reportCount
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                    }
                }
            }`;
        return this.apollo.query<{ evacuations: { totalCount: number; items: any[]; pageInfo: PageInfo } }>({
            query,
            variables,
        })
            .pipe(
                map(resp => {
                    const { totalCount, items, pageInfo } = resp.data.evacuations;
                    return {
                        totalCount,
                        data: items.map(parseJSONDates),
                        pageInfo,
                        variables,
                    } as PagedData<Evacuation>;
                })
            );
    }

    public getRecentlyCompletedEvacuations(): Observable<Evacuation[]> {
        const variables = {
            take: 5,
            skip: 0,
        };

        const query = getRecentEvacuationsQuery;
        return this.apollo.watchQuery<{ evacuations: { totalCount: number; items: Evacuation[]; pageInfo: PageInfo } }>({
            query,
            variables,
        })
            .valueChanges
            .pipe(
                map(resp => {
                    const { items } = resp.data.evacuations;
                    return items.map(parseJSONDates);
                })
            );
    }

    public getActiveEvacuations(): Observable<Evacuation[]> {
        const query = activeEvacuationsQuery;
        return this.apollo.watchQuery<{ evacuations: { items: any[] } }>({
            query,
        })
            .valueChanges
            .pipe(
                map(resp => resp.data.evacuations.items),
                map(evacuations => evacuations.map(parseJSONDates)),
                map(evacuations => evacuations as Evacuation[])
            );
    }

    public getEvacuationEventSubscription(): Observable<EvacEvent> {
        const query = evacuationsSubscription;
        const client = this.apollo.client;
        const cache = client.cache;
        return this.apollo.subscribe<{ subscribeEvacuations: EvacEvent }>({
            query,
        })
            .pipe(
                map(resp => resp.data.subscribeEvacuations),
                tap(event => {
                    const evacuationSub = event.evacuation;
                    if (event.status === 'STARTED') {
                        const evacuation = {
                            ...evacuationSub,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __typename: 'Evacuation',
                            building: {
                                ...evacuationSub.building,
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __typename: 'Building',
                            },
                        };
                        // eslint-disable-next-line no-console
                        console.debug('getEvacuationEventSubscription - started', event, evacuation);
                        const data: any = cache.readQuery({ query: activeEvacuationsQuery });
                        if (data !== null) {
                            const newCacheData = {
                                ...data,
                                evacuations: {
                                    ...data.evacuations,
                                    items: [
                                        ...data.evacuations.items,
                                        evacuation
                                    ]
                                }
                            };
                            cache.writeQuery({ query: activeEvacuationsQuery, data: newCacheData });
                        }
                        cache.evict({ id: `Building:${evacuationSub.buildingId}`, fieldName: 'evacuations' });
                    }
                    if (event.status === 'STOPPED') {
                        // eslint-disable-next-line no-console
                        console.debug('getEvacuationEventSubscription - stopped', event);
                        const data: any = cache.readQuery({ query: activeEvacuationsQuery });
                        if (data !== null) {
                            const newCacheData = {
                                ...data,
                                evacuations: {
                                    ...data.evacuations,
                                    items: data.evacuations.items.filter((evac: Evacuation) => evac.id !== evacuationSub.id),
                                }
                            };
                            cache.writeQuery({ query: activeEvacuationsQuery, data: newCacheData });
                            // eslint-disable-next-line no-console
                            console.debug('getEvacuationEventSubscription - cache change', data, newCacheData);
                        }
                        cache.evict({ id: `Building:${evacuationSub.buildingId}`, fieldName: 'evacuations' });
                    }
                }),
                map(event => parseJSONDates(event))
            );
    }

    public getReportEventSubscription(): Observable<ReportEvent> {
        const query = reportsSubscription;
        const client = this.apollo.client;
        return this.apollo.subscribe<{ subscribeReports: ReportEvent }>({
            query,
        })
            .pipe(
                map(resp => resp.data.subscribeReports),
                tap(event => {
                    if (event.status === 'CREATED') {
                        const reportSub = event.report;
                        const report = {
                            ...reportSub,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __typename: 'Report',
                            zone: {
                                ...reportSub.zone,
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __typename: 'Zone',
                            },
                            user: {
                                ...reportSub.user,
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __typename: 'User',
                            },
                        };
                        const evacuationId = reportSub.evacuationId;
                        const data = client.readQuery({
                            query: getEvacuationWithReportsQuery,
                            variables: {
                                evacuationId,
                            },
                        });
                        if (data) {
                            const newData = {
                                ...data,
                                evacuation: {
                                    ...data.evacuation,
                                    reports: [...data.evacuation.reports, report],
                                },
                            };
                            client.writeQuery({
                                query: getEvacuationWithReportsQuery,
                                variables: {
                                    evacuationId,
                                },
                                data: newData,
                            });
                        }
                    }
                }),
                map(event => parseJSONDates(event))
            );
    }

    public getBuildingWithZones(buildingId: number): Observable<Building> {
        const query = gql`
            query GetBuildings($buildingId: Int!) {
                building(id: $buildingId) {
                    id
                    code
                    name
                    zones {
                        id
                        name
                        floor
                    }
                }
            }`;
        return this.apollo.query<{ building: Building }>({
            variables: { buildingId },
            query
        })
            .pipe(
                map(resp => resp.data.building)
            );

    }

    public getEvacuationWithReports(evacuationId: number): Observable<Evacuation> {
        const query = getEvacuationWithReportsQuery;
        return this.apollo.watchQuery<{ evacuation: Evacuation }>({
            variables: { evacuationId },
            query,
        })
            .valueChanges
            .pipe(
                map(resp => resp.data.evacuation),
                map(evac => parseJSONDates(evac))
            );

    }

    public whoAmI(): Observable<User> {
        const query = whoAmIQuery;
        return this.apollo.query<{ whoAmI: User }>({
            query,
        })
            .pipe(
                map(resp => resp.data.whoAmI)
            );
    }

    public searchUsers(searchTxt: string): Observable<UserSearchInfo> {
        const query = searchUsersQuery;
        return this.apollo.query<{ users: { items: User[]; totalCount: number } }>({
            query,
            variables: {
                searchTxt,
            },
        })
            .pipe(
                map(resp => ({
                    users: resp.data.users.items,
                    count: resp.data.users.totalCount,
                }))
            );
    }

    public startEvacuation(buildingId: number): Observable<Evacuation> {
        const mutation = startEvacuationMutation;
        return this.apollo.mutate<{ startEvacuation: { evacuation: Evacuation } }>({
            mutation,
            variables: {
                buildingId,
            },
            update: (cache, { data }) => {
                const newEvacuation = data.startEvacuation.evacuation;
                const cacheData: any = cache.readQuery({ query: activeEvacuationsQuery });
                if (cacheData !== null) {
                    const newCacheData = {
                        ...cacheData,
                        evacuations: {
                            ...cacheData.evacuations,
                            items: [
                                ...cacheData.evacuations.items,
                                newEvacuation
                            ]
                        }
                    };
                    cache.writeQuery({ query: activeEvacuationsQuery, data: newCacheData });
                }
                cache.evict({ id: `Building:${buildingId}`, fieldName: 'evacuations' });
            },
        })
            .pipe(
                map(resp => resp.data.startEvacuation.evacuation)
            );
    }

    public stopEvacuation(evacuation: Evacuation): Observable<Evacuation> {
        const mutation = stopEvacuationMutation;
        return this.apollo.mutate<{ stopEvacuation: { evacuation: Evacuation } }>({
            mutation,
            variables: {
                id: evacuation.id,
            },
            update: (cache, { data }) => {
                const updatedEvacuation = data.stopEvacuation.evacuation;
                const cacheData: any = cache.readQuery({ query: activeEvacuationsQuery });
                if (cacheData !== null) {
                    const newCacheData = {
                        ...cacheData,
                        evacuations: {
                            ...cacheData.evacuations,
                            items: cacheData.evacuations.items.filter((filterEvacuation: Evacuation) => filterEvacuation.id !== updatedEvacuation.id)
                        }
                    };
                    cache.writeQuery({ query: activeEvacuationsQuery, data: newCacheData });
                }
                cache.evict({ id: `Building:${evacuation.buildingId}`, fieldName: 'evacuations' });
                cache.evict({ id: `Evacuation:${evacuation.id}`, fieldName: 'endTime' });
            },
        })
            .pipe(
                map(resp => resp.data.stopEvacuation.evacuation)
            );
    }

    public sendOrQueueReport(report: Report): Observable<Report> {
        if (this.online$.value && !this.slow$.value) {
            return this.createReport(report);
        } else {
            this.pushToReportQueue(report);
            return of(null as Report);
        }
    }

    public sendMessage(evacuation: Evacuation, message: string): Observable<boolean> {
        const mutation = sendMessageMutation;
        return this.apollo.mutate<{ notifyEvacuationSubscribers: boolean }>({
            mutation,
            variables: {
                evacuationId: evacuation.id,
                message,
                status: 'UPDATED',
            },
        })
            .pipe(
                map(resp => resp.data.notifyEvacuationSubscribers)
            );
    }

    public markAsReadMessage(messageId: number): Observable<boolean> {
        const mutation = markAsReadMessage;
        return this.apollo.mutate<{ markAsReadMessage: boolean }>({
            mutation,
            variables: {
                messageId,
            },
            update: (cache, { data }) => {
                const success = data.markAsReadMessage;
                const cacheData: any = cache.readQuery({ query: activeEvacuationsQuery });
                if (success && cacheData !== null) {
                    const newCacheData = {
                        ...cacheData,
                        evacuations: {
                            ...cacheData.evacuations,
                            items: cacheData.evacuations.items.map((mapEvacuation: Evacuation): Evacuation => ({
                                ...mapEvacuation,
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                MessagesNotRead: mapEvacuation.MessagesNotRead?.filter(msg => msg.id !== messageId)
                            } as Evacuation))
                        }
                    };
                    cache.writeQuery({ query: activeEvacuationsQuery, data: newCacheData });
                }
                cache.evict({ id: `Message:${messageId}`});
            },
        })
            .pipe(
                map(resp => resp.data.markAsReadMessage)
            );
    }

    public resetPassword(email: string): Observable<{ email: string; resetKey: string }> {
        const mutation = resetPasswordMutation;
        return this.apollo.mutate<{ resetPassword: { email: string; resetKey: string } }>({
            mutation,
            variables: {
                email,
            },
        })
            .pipe(
                map(resp => resp.data.resetPassword)
            );
    }

    public refetch() {
        this.apollo.client.reFetchObservableQueries().then(result => {
            // eslint-disable-next-line no-console
            console.debug('[DataProviderService] refetchActive - response', result);
        },
            error => {
                console.error('[DataProviderService] refetchActive - error', error);
            });
    }

    public downloadEvacuationReport(evacuationId: number): Observable<[Blob, string]> {
        const url = this.getEvacuationReportUrl(evacuationId);
        const token = this.getToken();
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        return this.http.get(url, {
            responseType: 'blob',
            observe: 'response',
            headers
        }).pipe(
            map(response => {
                const contentDispositionHeader = response.headers.get('content-disposition');
                const fname = parseContentDispositionHeader(contentDispositionHeader);
                return [response.body, fname];
            })
        );
    }

    private getEvacuationReportUrl(evacuationId: number): string {
        return environment.webApiEndpoint + `repo/evacuation/${evacuationId}`;
    }

    private createReport(report: Report): Observable<Report> {
        const mutation = createReportMutation;
        return this.apollo.mutate<{ addReport: { report: Report } }>({
            mutation,
            variables: {
                ...report
            },
            update: (cache, { data }) => {
                const newReport = data.addReport.report;
                const cacheData: any = cache.readQuery({ query: getEvacuationWithReportsQuery, variables: { evacuationId: report.evacuationId } });
                if (cacheData !== null) {
                    const newCacheData = {
                        ...cacheData,
                        evacuation: {
                            ...cacheData.evacuation,
                            reports: [...cacheData.evacuation.reports, newReport],
                        },
                    };
                    cache.writeQuery({ query: activeEvacuationsQuery, data: newCacheData, variables: { evacuationId: report.evacuationId } });
                }
                cache.evict({ id: `Evacuation:${report.evacuationId}`, fieldName: 'reports' });
            },
        })
            .pipe(
                map(resp => resp.data.addReport.report)
            );
    }

    private handleSendReportQueue() {
        // eslint-disable-next-line no-console
        console.debug('[DataProviderService] TODO handle report queue', this.sendReportQueue$.value);
        const report = this.popFromSendReportQueue();
        if (report !== null) {
            this.createReport(report).subscribe(response => {
                if (this.onSendReportSuccess) {
                    this.onSendReportSuccess(report);
                }
                // eslint-disable-next-line no-console
                console.debug('[DataProviderService] handleSendReportQueue - response', response);
                this.handleSendReportQueue();
            },
                error => {
                    if (this.onSendReportError) {
                        this.onSendReportError(error);
                    }
                    console.error('[DataProviderService] handleSendReportQueue - error', error);
                    this.pushToReportQueue(report);
                });
        }
    }

    private pushToReportQueue(report: Report) {
        this.sendReportQueue$.next([...this.sendReportQueue$.value, report]);
    }

    private popFromSendReportQueue(): Report {
        const queue = this.sendReportQueue$.value;
        if (queue.length > 0) {
            const report = queue[0];
            const newQueue = queue.slice(1);
            this.sendReportQueue$.next(newQueue);
            return report;
        }
        return null;
    }
}
