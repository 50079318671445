import { Component, OnDestroy, OnInit } from '@angular/core';
import { EvacEvent } from '@app/core/models/evacuation';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { beepGoodAudio } from '@app/core/util/asset-helper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'evac-confirm-message-dialog',
    templateUrl: './confirm-message-dialog.component.html',
    styleUrls: ['./confirm-message-dialog.component.scss']
})
export class ConfirmMessageDialogComponent implements OnInit, OnDestroy {

    public evacEvent: EvacEvent;
    public eventTime: Date;
    public loading = false;

    private subs = new Subscription();

    constructor(private dp: DataProviderService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) { }

    ngOnInit(): void {
        this.evacEvent = this.config.data.event;
        this.eventTime = new Date();
        this.subs.add(timer(0, 5000).subscribe(() => {
            beepGoodAudio.play();
        }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public confirm() {
        this.loading = true;
        this.dp.markAsReadMessage(this.evacEvent.id).subscribe(
            result => {
                if (result) {
                    this.ref.close();
                }
            },
            error => {
                console.error('error on mark as read', error);
            },
            () => {
                this.loading = false;
            });
    }
}
