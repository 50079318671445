import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Evacuation } from '@app/core/models/evacuation';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { countStatusReports } from '@app/core/util/stats-helper';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'evac-view-evacuations',
    templateUrl: './view-evacuations.component.html',
    styleUrls: ['./view-evacuations.component.scss']
})
export class ViewEvacuationsComponent implements OnInit, OnDestroy {

    @Input()
    public blocked = false;

    public evacuations$ = new BehaviorSubject<Evacuation[]>([]);
    public error: any;

    private subs = new Subscription();

    constructor(private dp: DataProviderService) { }

    ngOnInit(): void {
        this.subs.add(this.dp.getRecentlyCompletedEvacuations().subscribe(
            evacuations => {
                this.evacuations$.next(evacuations);
            },
            error => {
                this.error = error;
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public deltaFormatted(start: Date, end: Date): string {
        const delta = end.getTime() - start.getTime();
        const totalMinutes = Math.floor(delta / (1000 * 60));
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return (hours > 0 ? `${hours}h ` : '') + `${minutes}m`;
    }

    public countStats(evacuation: Evacuation): {
        success: number;
        danger: number;
        total: number;
    } {
        return countStatusReports(evacuation);
    }

    public downloadExport(evacuationId: number) {
        console.log('todo: download export from evac', evacuationId);
        this.dp.downloadEvacuationReport(evacuationId).subscribe(([blob, fname]) => {
            if (fname === null) {
                saveAs(blob);
            } else {
                saveAs(blob, fname);
            }
        });
    }
}
