import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';

@Component({
    selector: 'evac-scan-badge',
    templateUrl: './scan-badge.component.html',
    styleUrls: ['./scan-badge.component.scss']
})
export class ScanBadgeComponent implements OnInit {
    @Output()
    public scanResult = new EventEmitter<string>();

    public availableDevices: MediaDeviceInfo[];
    public deviceCurrent: MediaDeviceInfo;
    public hasDevices: boolean;
    public hasPermission: boolean;
    public torchEnabled = false;
    public torchAvailable = false;
    public tryHarder = false;

    public formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.QR_CODE,
    ];

    constructor() { }

    ngOnInit(): void {
    }

    public onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.availableDevices = devices;
        this.hasDevices = Boolean(devices && devices.length);
    }

    public onCodeResult(resultString: string) {
        this.scanResult.emit(resultString);
    }

    public onDeviceChange(device: MediaDeviceInfo) {
        if (this.deviceCurrent?.deviceId === device?.deviceId) {
            return;
        }
        this.deviceCurrent = device;
    }

    public onHasPermission(has: boolean) {
        this.hasPermission = has;
    }

    public onTorchCompatible(isCompatible: boolean): void {
        this.torchAvailable = isCompatible;
    }

    public selectNextDevice() {
        if (this.availableDevices && this.availableDevices.length > 1) {
            const i = this.availableDevices.findIndex(device => device.deviceId === this.deviceCurrent.deviceId);
            this.deviceCurrent = this.availableDevices[(i + 1) % this.availableDevices.length];
        }
    }
}
