import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/core/models/user';
import { DataProviderService } from '@app/core/services/data-provider.service';

const isTokenExpiredError = (error: Error): boolean => error['graphQLErrors'] && error['graphQLErrors'][0]['extensions']['code'] === 'AUTH_NOT_AUTHENTICATED';
const isWrongPasswordError = (error: Error): boolean => error['graphQLErrors'] && error['graphQLErrors'][0]['extensions']['code'] === 'EMAIL_PASSWORD_INVALID';
const isPasswordChangeError = (error: Error): boolean => error['graphQLErrors'] && error['graphQLErrors'][0]['extensions']['code'] === 'PWD_CHANGE_REQUIRED';

const errorMessage = (error: Error): string => {
    const graphQLError = error?.['graphQLErrors']?.[0]?.['message'] || null;
    const networkError = error?.['networkError']?.['error']?.['errors']?.[0]?.['message'] || null;
    const baseMessage = error?.['message'] || null;
    return graphQLError || networkError || baseMessage || null;
};

@Component({
    moduleId: module.id,
    templateUrl: 'login-page.component.html',
    styleUrls: ['login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
    public token: string;
    public tokenUser: User;
    public error: any;
    public errorMessage: string;

    public email: string;
    public password: string;
    public showNewPasswordFields: boolean;
    public newPassword: string;
    public newPasswordAgain: string;
    public device = navigator;

    public oneAlphaNumPattern = '^(?=.*[a-zA-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{2,}$';

    constructor(private router: Router, private dp: DataProviderService) { }

    public ngOnInit(): void {
        this.error = null;
        this.errorMessage = null;
        this.email = this.dp.getLoginName() || '';
        this.password = '';
        this.newPassword = '';
        this.newPasswordAgain = '';
        this.showNewPasswordFields = false;
        this.token = this.dp.getToken();
        if (this.token) {
            this.setTokenUser();
        }
    }

    public login() {
        this.dp.login(this.email, this.password).subscribe(token => {
            this.token = token;
            this.errorMessage = null;
            this.error = null;
            this.setTokenUser();
        },
        error => {
            if (isWrongPasswordError(error)) {
                console.error('[LoginPageComponent] wrong credentials', error);
                this.errorMessage = 'Wrong credentials, please try again.';
                this.error = null;
            } else if (isPasswordChangeError(error)) {
                console.error('[LoginPageComponent] password change required', error);
                this.errorMessage = 'New account, please set password.';
                this.error = null;
                this.showNewPasswordFields = true;
            } else {
                console.error('[LoginPageComponent]', error);
                this.errorMessage = `Error during sign in: ${ errorMessage(error) || '-' }`;
                this.error = error;
            }
            this.token = null;
            this.tokenUser = null;
        });
    }

    public changePassword() {
        this.dp.changePassword(this.email, this.password, this.newPassword).subscribe(result => {
            if (result) {
                this.errorMessage = null;
                this.error = null;
                this.showNewPasswordFields = false;
                this.password = this.newPassword;
                this.login();
            } else {
                this.errorMessage = 'Password change failed.';
                this.error = null;
            }
        },
        error => {
            console.error('[LoginPageComponent]', error);
            this.errorMessage = `Error during change password: ${ errorMessage(error) || '-' }`;
            this.error = error;
        });
    }

    public logout() {
        this.token = null;
        this.dp.logout();
    }

    private setTokenUser() {
        this.dp.getAuthenticatedUser().subscribe(user => {
            // eslint-disable-next-line no-console
            console.debug('[LoginPageComponent] user:', user);
            this.tokenUser = user;
            this.error = null;
            this.router.navigate(['report']);
        },
        error => {
            if (isTokenExpiredError(error)) {
                console.error('[LoginPageComponent] token expired, logout forced', error);
                this.errorMessage = 'Session expired, please sign in.';
                this.error = null;
                this.logout();
            } else {
                console.error('[LoginPageComponent]', error);
                this.errorMessage = `Error during auth check: ${ errorMessage(error) || '-' }`;
                this.error = error;
            }
            this.tokenUser = null;
        });
    }
}
