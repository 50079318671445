<h4><i class="far fa-inbox"></i> Unread messages</h4>
<p-table [value]="messages">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col>
            <col>
            <col style="width: 3em">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="body" let-localMessage>
        <tr>
            <td>
                <ng-container *ngIf="!isToday(localMessage.message.createdOn)">
                    <i class="fal fa-calendar"></i> {{ localMessage.message.createdOn | date:'d MMM yyyy' }}<br/>
                </ng-container>
                <i class="fal fa-clock"></i> {{ localMessage.message.createdOn | date:'HH:mm' }}<br/>
                <i class="fal fa-user"></i> {{ localMessage.message.user.firstName }} {{ localMessage.message.user.lastName }}<br/>
            </td>
            <td>
                <i class="fal fa-info-circle"></i> {{ localMessage.message.status }}<br/>
                <i class="fal fa-comment"></i>&nbsp;<strong>{{ localMessage.message.text }}</strong><br/>
            </td>
            <td>
                <button *ngIf="!localMessage.read" pButton type="button" icon="far fa-eye" (click)="markAsRead(localMessage)" [loading]="localMessage.loading"></button>
                <p-tag *ngIf="localMessage.read" severity="success">READ</p-tag>
            </td>
        </tr>
    </ng-template>
</p-table>

<button pButton label="close" icon="far fa-times" (click)="close()"></button>
