import { Component, Input, OnInit } from '@angular/core';
import { Evacuation, Report } from '@app/core/models/evacuation';
import { isToday } from '@app/core/util/stats-helper';

@Component({
    selector: 'evac-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

    @Input()
    public evacuation: Evacuation;

    @Input()
    public reports: Report[];

    public isToday = isToday;

    constructor() { }

    ngOnInit(): void {
    }

}
