import { Component, OnInit } from '@angular/core';
import { Building } from '@app/core/models/building';
import { Zone } from '@app/core/models/zone';
import { groupZonesByFloor } from '@app/core/util/data-helper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'evac-select-zone-dialog',
    templateUrl: './select-zone-dialog.component.html',
    styleUrls: ['./select-zone-dialog.component.scss']
})
export class SelectZoneDialogComponent implements OnInit {

    public building: Building;
    public floors: string[];
    public selectedFloor: string;
    public zones: Zone[];

    private floorToZone: Map<string, Zone[]>;

    constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) { }

    ngOnInit(): void {
        this.building = this.config.data.building;

        const zonesByFloor = groupZonesByFloor(this.building);
        this.floors = zonesByFloor.map(zoneByFloor => zoneByFloor.floor);
        this.floorToZone = new Map<string, Zone[]>(zonesByFloor.map(zoneByFloor => [zoneByFloor.floor, zoneByFloor.zones]));

        this.selectedFloor = null;
        this.zones = null;
    }

    public selectFloor(floor: string) {
        this.selectedFloor = floor;
        this.zones = floor ? this.floorToZone.get(floor) : null;
    }

    public selectZone(zone: Zone) {
        this.ref.close(zone);
    }

    public cancel() {
        this.ref.close(null);
    }
}
