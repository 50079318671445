import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Building } from '@app/core/models/building';
import { Evacuation } from '@app/core/models/evacuation';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BroadcastMessageDialogComponent } from '../broadcast-message-dialog/broadcast-message-dialog.component';

@Component({
    selector: 'evac-manage-evacuations',
    templateUrl: './manage-evacuations.component.html',
    styleUrls: ['./manage-evacuations.component.scss'],
    providers: [ConfirmationService, DialogService],
})
export class ManageEvacuationsComponent implements OnInit, OnDestroy {

    @Input()
    public blocked = false;

    public buildings$: Observable<Building[]>;
    public error: Error;

    private subs = new Subscription();

    constructor(
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
        private dp: DataProviderService) { }

    ngOnInit(): void {
        this.buildings$ = this.dp.getBuildings().pipe(
            catchError(error => {
                this.error = error;
                throw error;
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public startEvacuation(building: Building) {
        const message = `Start an evacuation for ${building.name}?`;

        const startEvacTask = () => {
            this.dp.startEvacuation(building.id).subscribe(evacuation => {
                console.debug('evacuation started', evacuation); // eslint-disable-line
            },
                error => {
                    this.error = error;
                    console.error('[ManageEvacuationsComponent] startEvacuation', error);
                });
        };

        this.confirmationService.confirm({
            message,
            accept: startEvacTask,
            key: 'evacAdminConfirm',
        });
    }

    public stopEvacuation(building: Building, evacuation: Evacuation) {
        const message = `Stop the evacuation for ${building?.name || '-'}?`;

        const stopEvacTask = () => {
            this.dp.stopEvacuation(evacuation).subscribe(newEvacuation => {
                console.debug('evacuation stopped', newEvacuation); // eslint-disable-line
            },
                error => {
                    this.error = error;
                    console.error('[ManageEvacuationsComponent] stopEvacuation', error);
                });
        };

        this.confirmationService.confirm({
            message,
            accept: stopEvacTask,
            key: 'evacAdminConfirm',
        });
    }

    public findActiveEvacuations(building: Building): Evacuation[] {
        const activeEvacuations = building.evacuations.filter(evacuation => evacuation.endTime === null);
        return activeEvacuations.length > 0 ? activeEvacuations : null;
    }

    public broadcast(building: Building, evacuation: Evacuation) {
        const ref = this.dialogService.open(BroadcastMessageDialogComponent, {
            data: {
                evacuation,
                building,
            },
            width: '95%',
            header: building.name,
            closable: false,
        });

        ref.onClose.subscribe((message: string) => {
            if (message) {
                this.dp.sendMessage(evacuation, message).subscribe(result => {
                    // eslint-disable-next-line no-console
                    console.debug('[ManageEvacuationsComponent] broadcast response', result);
                });
            }
        });
    }
}
