import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'evac-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    @Input()
    public set data(data: {success?: number; warning?: number; danger?: number; total?: number}) {
        this.success = data.success || 0;
        this.warning = data.warning || 0;
        this.danger = data.danger || 0;
        const total = data.total || this.success + this.warning + this.danger || 1;

        this.successPct = (this.success > 0) ? `${this.success * 100 / total}%` : null;
        this.warningPct = (this.warning > 0) ? `${this.warning * 100 / total}%` : null;
        this.dangerPct = (this.danger > 0) ? `${this.danger * 100 / total}%` : null;
    };

    @Input()
    public showNumbers = false;

    public success: number = 0;
    public warning: number = 0;
    public danger: number = 0;

    public successPct: string = null;
    public warningPct: string = null;
    public dangerPct: string = null;

    constructor() { }

    ngOnInit(): void {
    }

}
