import { Building } from '../models/building';
import { Zone } from '../models/zone';

export const sortFloors = (floors: Array<string>, buildingCode: string): string[] => {
    if (buildingCode === 'JL') {
        const lowerFloors = floors.filter(floor => floor.startsWith('0'));
        const upperFloors = floors.filter(floor => !floor.startsWith('0'));
        lowerFloors.sort();
        upperFloors.sort().reverse();
        return [...upperFloors, ...lowerFloors];
    } else {
        return [...floors].sort().reverse();
    }
};

export type ZonesByFloor = Array<{floor: string; zones: Zone[]}>;

export const groupZonesByFloor = (building: Building): ZonesByFloor => {
    const zones = building.zones;
    const floorGrid = new Map<string, Zone[]>();
    zones.forEach((zone) => {
        const floorZones = floorGrid.get(zone.floor) || [];
        floorGrid.set(zone.floor, [...floorZones, zone]);
    });
    const floors = sortFloors([...floorGrid.keys()], building.code);
    return floors.map(floor => ({floor, zones: floorGrid.get(floor).slice().sort((a, b) => a.name.localeCompare(b.name))}));
};
