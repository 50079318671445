<zxing-scanner [torch]="torchEnabled" [device]="deviceCurrent" (deviceChange)="onDeviceChange($event)"
    (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled" [tryHarder]="tryHarder"
    (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)"
    (torchCompatible)="onTorchCompatible($event)">
</zxing-scanner>

<ng-container *ngIf="hasDevices; else noDevicesFound">
    {{ deviceCurrent?.label }}
    <button *ngIf="availableDevices.length > 1" pButton type="button" label="Switch camera" icon="far fa-video"
        iconPos="left" (click)="selectNextDevice()"></button>
    <button *ngIf="torchAvailable" pButton type="button" label="Flashlight" icon="far fa-flashlight"
        iconPos="left" (click)="torchEnabled = !torchEnabled" [ngClass]="{'p-button-outlined' : !torchEnabled}"></button>
</ng-container>
<ng-template #noDevicesFound>
    <p>No camera devices available.</p>
</ng-template>