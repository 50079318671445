<div class="progress">
    <div *ngIf="successPct" class="progress-bar bg-success" [style]="{width: successPct}">
        {{ showNumbers ? success : '' }}
    </div>
    <div *ngIf="warningPct" class="progress-bar bg-warning" [style]="{width: warningPct}">
        {{ showNumbers ? warning : '' }}
    </div>
    <div *ngIf="dangerPct" class="progress-bar bg-danger" [style]="{width: dangerPct}">
        {{ showNumbers ? danger : ''}}
    </div>
</div>
