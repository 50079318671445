import { Component } from '@angular/core';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { environment } from '@app/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'evac-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public appVersion: string = 'v.' + environment.version;
  public appDescription: string = environment.applicationDescription;
  public production = environment.production;
  public whereAmI$: Observable<string>;

  constructor(private dp: DataProviderService) {
    if (!this.production) {
      this.whereAmI$ = this.dp.getWhereAmI();
    }
  }
}
