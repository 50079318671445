/* modules */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';

/* models */

/* services */


@Injectable()
export class SampleSkeletonGuard implements CanActivate {

    constructor(private _router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean {

        const secure: boolean = route.data['secure'];

        if (!secure) {
            alert('Access Denied');
            this._router.navigate(['views/public']);
            return false;
        }
        return true;
    }
}
