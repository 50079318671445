import { Component, OnInit } from '@angular/core';
import { beepErrorAudio, beepGoodAudio } from '@app/core/util/asset-helper';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'evac-scan-dialog',
    templateUrl: './scan-dialog.component.html',
    styleUrls: ['./scan-dialog.component.scss']
})
export class ScanDialogComponent implements OnInit {

    public qrResult: string;
    public qrError: string;

    constructor(private ref: DynamicDialogRef) { }

    ngOnInit(): void {
    }

    public onCodeResult(resultString: string) {
        // eslint-disable-next-line no-console
        console.debug('onCodeResult', resultString);
        this.qrResult = '';
        this.qrError = null;
        let resultObject = null;
        try {
            resultObject = JSON.parse(resultString);
        } catch (error) {
            beepErrorAudio.play();
            console.error('QR data parse error', error);
            this.qrError = 'Invalid QR code';
        }
        if (resultObject && (resultObject.app === 'EVAC2') && resultObject.email) {
            this.qrResult = resultObject.email;
            this.qrError = null;
            beepGoodAudio.play();
            this.ref.close(this.qrResult);
        } else {
            beepErrorAudio.play();
            console.error('Not an EVAC2 QR code', resultObject);
            this.qrError = 'Not an EVAC2 QR code';
        }
    }

    public closeDialog() {
        this.ref.close();
    }
}
