import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/core/models/user';
import { DataProviderService, UserSearchInfo } from '@app/core/services/data-provider.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'evac-admin-reset-password',
    templateUrl: './admin-reset-password.component.html',
    styleUrls: ['./admin-reset-password.component.scss']
})
export class AdminResetPasswordComponent implements OnInit {

    @Input()
    public blocked = false;

    public email: string;
    public resetKey: string;
    public error: any;
    public errorMessage: string;
    public resetDone: boolean;
    public copied: boolean;
    public users: User[];
    public user: User;
    public userCount: number;
    public userFound: boolean;

    constructor(private dp: DataProviderService) { }

    ngOnInit(): void {
        this.clearForm();
    }

    public resetPassword() {
        this.dp.resetPassword(this.user.email)
            .pipe(take(1))
            .subscribe(
                ({ resetKey, email }) => {
                    this.resetKey = resetKey;
                    this.email = email;
                    this.resetDone = true;
                    this.error = null;
                    this.errorMessage = null;
                    this.copied = false;
                },
                error => {
                    console.error('[AdminResetPasswordComponent] resetPassword', error);
                    this.error = error;
                    this.errorMessage = error?.message;
                }
            );
    }

    public clearForm() {
        this.email = '';
        this.resetKey = '';
        this.error = null;
        this.errorMessage = null;
        this.resetDone = false;
        this.copied = false;
        this.userFound = false;
        this.users = [];
        this.user = null;
        this.userCount = 0;
    }

    public copyKeyToClipboard() {
        if (this.resetKey) {
            navigator?.clipboard?.writeText(this.resetKey).then(() => {
                this.copied = true;
            });
        }
    }

    public setUsers(info: UserSearchInfo) {
        if (info !== null) {
            const { users, count } = info;
            this.users = users;
            this.user = users.length === 1 ? users[0] : null;
            this.userCount = count;
            this.userFound = users.length === 1;
        } else {
            this.userFound = null;
            this.user = null;
        }
    }
}
