import { Component, OnDestroy, OnInit } from '@angular/core';
import { Building } from '@app/core/models/building';
import { EvacEvent, Evacuation, Message, Report, ReportEvent } from '@app/core/models/evacuation';
import { Role, User } from '@app/core/models/user';
import { Zone } from '@app/core/models/zone';
import { DataProviderService, UserSearchInfo } from '@app/core/services/data-provider.service';
import { isToday } from '@app/core/util/stats-helper';
import { environment } from '@app/environment';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ConfirmMessageDialogComponent } from './components/confirm-message-dialog/confirm-message-dialog.component';
import { ScanDialogComponent } from './components/scan-dialog/scan-dialog.component';
import { SelectZoneDialogComponent } from './components/select-zone-dialog/select-zone-dialog.component';
import { UnreadMessagesDialogComponent } from './components/unread-messages-dialog/unread-messages-dialog.component';

const sortReportsLatestFirst = (a: Report, b: Report) => b.lastmodifiedOn.getTime() - a.lastmodifiedOn.getTime();

@Component({
    moduleId: module.id,
    templateUrl: 'report-page.component.html',
    styleUrls: ['report-page.component.scss'],
    providers: [MessageService, DialogService]
})
export class ReportPageComponent implements OnInit, OnDestroy {
    public buildings$: Observable<Building[]>;

    public error: any;

    public email: string;
    public authUser: User = null;
    public authUserRoles: Role[];
    public selectedAuthUserRoles: Role[];

    public user: User;
    public users: User[];
    public userCount: number;
    public userFound: boolean;

    public activeEvacuations: Evacuation[] = [];
    public get selectedEvacuation(): Evacuation {
        return this.selectedEvacuation$.value;
    };
    public selectedEvacuationMessages: Message[] = [];

    public evacMenu: MenuItem[];
    public evacMenuActive: MenuItem;
    public zones: Zone[];
    public building: Building;
    public mySelectedZone: Zone;
    public selectedZone: Zone;
    public reports: Report[] = [];
    public myReport: Report = null;
    public reportAgain = false;
    public otherReport: Report = null;
    public reportOtherAgain = false;
    public reportQueue: Report[];

    public myComment: string;
    public comment: string;

    public online: boolean;
    public slow: boolean;

    public isToday = isToday;

    public debugMode = environment.debugMode;

    private subs = new Subscription();
    private now: number;
    private selectedEvacuation$ = new BehaviorSubject<Evacuation>(null);

    constructor(private messageService: MessageService, private dialogService: DialogService, private dp: DataProviderService) { }

    ngOnInit(): void {
        this.zones = [];
        this.building = null;
        this.now = Date.now();
        this.users = [];
        this.userCount = 0;
        this.userFound = null;

        this.reportQueue = [];
        this.subs.add(this.dp.sendReportQueue$.subscribe(queue => {
            this.reportQueue = queue;
        }));

        this.subs.add(this.dp.getAuthenticatedUser().subscribe(
            (user) => {
                // eslint-disable-next-line no-console
                console.debug('auth user', user);
                this.authUser = user;
                this.authUserRoles = user.roles || [];
                this.selectedAuthUserRoles = this.authUserRoles.slice();
            },
            (err) => {
                console.error('auth error', err);
                this.authUser = null;
            }
        ));

        this.subs.add(timer(1000, 1000).subscribe(_ => {
            this.now = Date.now();
        }));

        this.subs.add(this.dp.getActiveEvacuations().subscribe(
            activeEvacuations => {
                this.activeEvacuations = activeEvacuations;
                const i = activeEvacuations.findIndex(evac => evac.id === this.selectedEvacuation$.value?.id);
                if (activeEvacuations.length > 0) {
                    this.selectedEvacuation$.next(activeEvacuations[i !== -1 ? i : 0]);
                } else {
                    this.selectedEvacuation$.next(null);
                }
                this.evacMenu = this.activeEvacuations.map(evac => ({
                    label: evac.building.name,
                    command: () => this.selectedEvacuation$.next(evac),
                }));
                this.evacMenuActive = this.evacMenu.length > 0 ? this.evacMenu[i !== -1 ? i : 0] : null;
            },
            error => {
                this.error = error;
            })
        );

        this.subs.add(this.selectedEvacuation$.pipe(
            tap(evacuation => {
                // eslint-disable-next-line no-console
                console.debug('selectedEvacuation', evacuation);
            }),
            switchMap(evacuation => evacuation ? this.dp.getBuildingWithZones(evacuation.buildingId) : of(null as Building))
        ).subscribe(building => {
            // eslint-disable-next-line no-console
            console.debug('set zones', building);
            this.building = building;
            this.zones = building ? building.zones.slice().sort((a, b) => {
                const cmpFloor = a.floor.localeCompare(b.floor);
                const cmpName = a.name.localeCompare(b.name);
                return cmpFloor === 0 ? cmpName : cmpFloor;
            }) : [];
            this.mySelectedZone = this.authUser?.zone ? this.zones.find(zone => zone.id === this.authUser.zone.id) || null : null;
            this.selectedZone = this.user?.zone ? this.zones.find(zone => zone.id === this.user.zone.id) || null : null;
        }));

        this.subs.add(this.selectedEvacuation$.pipe(
            tap(evacuation => {
                const messages = evacuation?.MessagesNotRead || [];
                this.selectedEvacuationMessages = messages.slice()
                    // .filter(message => message.status.toLowerCase() === 'updated')
                    .sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
            }),
            switchMap(evacuation => evacuation ? this.dp.getEvacuationWithReports(evacuation.id) : of(null as Evacuation))
        ).subscribe(evacuationWithReports => {
            // eslint-disable-next-line no-console
            console.debug('set reports', evacuationWithReports);
            this.reports = evacuationWithReports ? evacuationWithReports.reports.slice().sort(sortReportsLatestFirst) : [];
            this.checkMyReport();
            this.checkOtherReport();
        }));

        this.subs.add(this.dp.getEvacuationEventSubscription().subscribe(event => {
            this.handleEvacEvent(event);
        }));

        this.subs.add(this.dp.getReportEventSubscription().subscribe(event => {
            this.handleReportEvent(event);
        }));

        this.subs.add(this.dp.online$.subscribe(status => {
            this.online = status;
        }));

        this.subs.add(this.dp.slow$.subscribe(status => {
            this.slow = status;
        }));

        this.dp.onSendReportSuccess = (_: Report) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your queued report has been sent successfully.' });
        };

        this.dp.onSendReportError = (error: Error) => {
            this.error = error;
            console.error('sendReport - error', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Your queued report could not be sent.' });
        };
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public showSelectZoneDialog(other: boolean) {
        const ref = this.dialogService.open(SelectZoneDialogComponent, {
            data: {
                building: this.building,
            },
            width: '90%',
            showHeader: false,
        });

        ref.onClose.subscribe((zone: Zone) => {
            if (zone) {
                if (other) {
                    this.selectedZone = zone;
                } else {
                    this.mySelectedZone = zone;
                }
            }
        });
    }

    public showScanDialog() {
        const ref = this.dialogService.open(ScanDialogComponent, {
            width: '95%',
            header: 'Scan Steward Badge',
            modal: true,
            closable: false,
            styleClass: 'p-fluid',
        });

        ref.onClose.subscribe((email: string) => {
            if (email) {
                this.email = email;
            }
        });
    }

    public showUnreadMessagesDialog(messages: Message[]) {
        const ref = this.dialogService.open(UnreadMessagesDialogComponent, {
            data: {
                messages,
            },
            width: '95%',
            showHeader: false,
            modal: true,
            closable: false,
            styleClass: 'p-fluid',
        });

        ref.onClose.subscribe(() => {
            // eslint-disable-next-line no-console
            console.debug('Unread messages dialog closed', messages);
        });
    }

    public sendMyOKReport() {
        this.sendReport('OK', this.authUser, this.myComment, this.mySelectedZone);
    }

    public sendMyNOKReport() {
        this.sendReport('NOK', this.authUser, this.myComment, this.mySelectedZone);
    }

    public sendOtherOKReport() {
        this.sendReport('OK', this.user, this.comment, this.selectedZone);
    }

    public sendOtherNOKReport() {
        this.sendReport('NOK', this.user, this.comment, this.selectedZone);
    }

    public calculateEvacuationDuration(evac: Evacuation): any {
        const s = evac.startTime.getTime();
        const e = evac.endTime ? evac.endTime.getTime() : this.now;
        const deltaSecs = Math.floor((e - s) / 1000);
        const secs = deltaSecs % 60;
        const deltaMins = Math.floor(deltaSecs / 60);
        const mins = deltaMins % 60;
        const deltaHours = Math.floor(deltaMins / 60);
        const parts = [];
        if (deltaHours > 0) {
            parts.push(`${deltaHours}h`);
        }
        if (mins > 0) {
            parts.push(`${mins}m`);
        }
        if (secs > 0) {
            parts.push(`${secs}s`);
        }
        return {
            hours: deltaHours,
            mins,
            secs,
        };
    }

    public setUsers(info: UserSearchInfo) {
        if (info !== null) {
            const { users, count } = info;
            this.users = users;
            this.userCount = count;
            if (users.length === 1) {
                this.userFound = true;
                this.user = users[0];
                this.selectedZone = this.user?.zone ? this.zones.find(zone => zone.id === this.user.zone.id) || null : null;
                this.checkOtherReport();
            } else {
                this.userFound = false;
            }
        } else {
            this.userFound = null;
        }
    }

    public hasAdminRole() {
        return this.hasRole('admin');
    }

    public hasStewardRole() {
        return this.hasRole('steward');
    }

    public hasStatisticRole() {
        return this.hasRole('statistic');
    }

    private hasRole(roleName: string): boolean {
        return this.authUser && this.selectedAuthUserRoles.some(role => role.name.toLocaleLowerCase() === roleName);
    }

    private handleEvacEvent(event: EvacEvent) {
        // eslint-disable-next-line no-console
        console.debug('[ReportPageComponent] evac event', event);
        if (event.status === 'UPDATED') {
            this.showMessageDialog(event);
        } else {
            // eslint-disable-next-line no-console
            console.debug('[ReportPageComponent] evac event, refetch', event);
            this.dp.refetch();
        }
    }

    private showMessageDialog(event: EvacEvent) {
        const ref = this.dialogService.open(ConfirmMessageDialogComponent, {
            data: {
                event,
            },
            width: '95%',
            showHeader: false,
            modal: true,
            closable: false,
            styleClass: 'p-fluid',
        });

        ref.onClose.subscribe(() => {
            // eslint-disable-next-line no-console
            console.debug('[ReportPageComponent] broadcast ack', event);
        });
    }

    private handleReportEvent(event: ReportEvent) {
        // eslint-disable-next-line no-console
        console.debug('[ReportPageComponent] report event', event);
    }

    private sendReport(status: string, user: User, comment: string, zone: Zone) {
        const report: Report = {
            zoneId: zone.id,
            evacuationId: this.selectedEvacuation$.value.id,
            userId: user.id,
            status,
            comment,
        };
        this.dp.sendOrQueueReport(report).subscribe(response => {
            if (response === null) {
                // eslint-disable-next-line no-console
                console.debug('sendReport - report queued', report);
                this.messageService.add({ severity: 'warn', summary: 'Queued', detail: 'Your report will be sent when online.' });
            } else {
                // eslint-disable-next-line no-console
                console.debug('sendReport - response', response);
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your report has been sent successfully.' });
            }
            this.reportAgain = false;
            this.reportOtherAgain = false;
            this.comment = '';
            this.myComment = '';
        },
            error => {
                this.error = error;
                console.error('sendReport - error', error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Your report could not be sent. Please check your network connection and try again when online.' });
            });
    }

    private checkMyReport() {
        this.myReport = this.searchLatestUserReport(this.authUser);
    }

    private checkOtherReport() {
        this.otherReport = this.searchLatestUserReport(this.user);
    }

    private searchLatestUserReport(user: User): Report {
        if (this.reports && this.reports.length > 0 && user) {
            const userReports = this.reports.filter(report => report.user?.id === user.id);
            return userReports.length > 0 ? userReports[0] : null;
        } else {
            return null;
        }
    }
}
