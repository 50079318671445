import { NgModule } from '@angular/core';
import { Routes , RouterModule } from '@angular/router';

import { LoginGuard } from './core/guards/login.guard';
import { LoginPageComponent } from './pages/login-page.component';
import { ReportPageComponent } from './pages/report-page.component';

export const routes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
    },
    {
        path: 'report',
        canActivate: [LoginGuard],
        component: ReportPageComponent,
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false, useHash: false,
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule {
}

export const routedComponents = [LoginPageComponent, ReportPageComponent];
