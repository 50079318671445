import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InMemoryCache, ApolloLink, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '@app/environment';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InplaceModule } from 'primeng/inplace';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OrderListModule } from 'primeng/orderlist';
import { PasswordModule } from 'primeng/password';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule, routedComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiControllers } from './core/constants/api.controllers.const';
import { CoreModule } from './core/core.module';
import { LoadingService } from './core/services/loading.service';
import { AdminResetPasswordComponent } from './pages/components/admin-reset-password/admin-reset-password.component';
import { BroadcastMessageDialogComponent } from './pages/components/broadcast-message-dialog/broadcast-message-dialog.component';
import { ConfirmMessageDialogComponent } from './pages/components/confirm-message-dialog/confirm-message-dialog.component';
import { ManageEvacuationsComponent } from './pages/components/manage-evacuations/manage-evacuations.component';
import { ProgressBarComponent } from './pages/components/progress-bar/progress-bar.component';
import { ReportListComponent } from './pages/components/report-list/report-list.component';
import { ScanBadgeComponent } from './pages/components/scan-badge/scan-badge.component';
import { ScanDialogComponent } from './pages/components/scan-dialog/scan-dialog.component';
import { SearchUserComponent } from './pages/components/search-user/search-user.component';
import { SelectZoneDialogComponent } from './pages/components/select-zone-dialog/select-zone-dialog.component';
import { StatisticsComponent } from './pages/components/statistics/statistics.component';
import { UnreadMessagesDialogComponent } from './pages/components/unread-messages-dialog/unread-messages-dialog.component';
import { ViewEvacuationsComponent } from './pages/components/view-evacuations/view-evacuations.component';
import { ZoneStatusComponent } from './pages/components/zone-status/zone-status.component';
import { FooterComponent } from './pages/shared/footer/footer.component';
import { HeaderComponent } from './pages/shared/header/header.component';
import { PasswordMatchValidatorDirective } from './pages/shared/password-match.directive';

const createApollo = (httpLink: HttpLink, ls: LoadingService) => {
	const basic = setContext((_operation, _context) => ({
		headers: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Accept: 'charset=utf-8'
		}
	}));

	const auth = setContext((_operation, _context) => {
		// eslint-disable-next-line no-console
		console.debug('createApollo - setContext auth', _operation, _context);
		const token = localStorage.getItem('token');

		if (token === null) {
			return {};
		} else {
			return {
				headers: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Authorization: `Bearer ${token}`
				}
			};
		}
	});

	const registerListener = () => {
		(wsLink as any)?.subscriptionClient?.client?.addEventListener('message', (event: MessageEvent<string>) => {
			const data = JSON.parse(event.data);
			if (data['type'] === 'ka') {
				ls.beat();
			} else {
				console.log('web socket data', data);
			}
		});
	};

	const wsLink = new WebSocketLink({
		uri: environment.webSocketApiEndpoint,
		options: {
			reconnect: true,
			connectionParams: () => {
				const token = localStorage.getItem('token');
				if (token === null) {
					console.error('createApollo - ws connect auth: no token found in local storage');
				}
				// eslint-disable-next-line no-console
				console.debug('createApollo - ws connect auth', token);
				const params = (token === null) ? {} : { authToken: `${token}` };
				return params;
			},
			lazy: true,
			connectionCallback: (error) => {
				if (error) {
					console.error('error during web socket setup', error);
				} else {
					// eslint-disable-next-line no-console
					console.debug('web socket setup completed');
					registerListener();
				}
			},
		},
	});

	// eslint-disable-next-line no-console
	console.debug('wsLink', wsLink);

	const queryLink = httpLink.create({ uri: environment.webApiEndpoint });

	const splitLink = split(
		({ query }: any) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			);
		},
		wsLink,
		queryLink,
	);

	const link = ApolloLink.from([basic, auth, splitLink]);
	const cache = new InMemoryCache();

	return {
		link,
		cache,
		connectToDevtools: true
	};
};

@NgModule({
	declarations: [
		AppComponent,
		...routedComponents,
		HeaderComponent,
		FooterComponent,
		ReportListComponent,
		ManageEvacuationsComponent,
		StatisticsComponent,
		ZoneStatusComponent,
		ProgressBarComponent,
		ViewEvacuationsComponent,
		PasswordMatchValidatorDirective,
		AdminResetPasswordComponent,
		SearchUserComponent,
		ScanBadgeComponent,
		SelectZoneDialogComponent,
		BroadcastMessageDialogComponent,
		ScanDialogComponent,
  ConfirmMessageDialogComponent,
  UnreadMessagesDialogComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		CommonModule,
		ZXingScannerModule,
		CoreModule,
		TableModule,
		CardModule,
		FormsModule,
		ButtonModule,
		ChipModule,
		ChipsModule,
		TagModule,
		ChartModule,
		AutoCompleteModule,
		DialogModule,
		DropdownModule,
		AvatarModule,
		AvatarGroupModule,
		BadgeModule,
		ToastModule,
		TabMenuModule,
		InputTextModule,
		InputTextareaModule,
		PasswordModule,
		OrderListModule,
		DataViewModule,
		TabViewModule,
		MessagesModule,
		MessageModule,
		InplaceModule,
		DynamicDialogModule,
		ConfirmDialogModule,
		BlockUIModule,
		SelectButtonModule,
		ZXingScannerModule,
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.serviceWorkerEnabled,
			registrationStrategy: 'registerWhenStable:30000'
		}),
	],
	providers: [
		ApiControllers,
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink, LoadingService],
		},
		MessageService,
	],
	bootstrap: [
		AppComponent,
	],
	entryComponents: [
		SelectZoneDialogComponent,
		BroadcastMessageDialogComponent,
		ScanDialogComponent,
		ConfirmMessageDialogComponent,
	]
})
export class AppModule { }
