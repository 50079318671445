import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from '@angular/forms';

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('newPassword');
    const passwordAgain = control.get('newPasswordAgain');

    return password && passwordAgain && password.value !== passwordAgain.value ? { noPasswordMatch: true } : null;
};

@Directive({
    selector: '[evacPasswordMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatchValidatorDirective, multi: true }]
})
export class PasswordMatchValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const err =  passwordMatchValidator(control);
        return err;
    }
}
