import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Evacuation } from '@app/core/models/evacuation';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { countStatusReports } from '@app/core/util/stats-helper';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'evac-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {

    @Input()
    public set evacuationId(id: number) {
        this.evacuationId$.next(id);
    }

    public data: any;

    private evacuationId$ = new BehaviorSubject<number>(null);
    private subs = new Subscription();

    constructor(private dp: DataProviderService) { }

    ngOnInit(): void {
        this.data = null;
        const sub = this.evacuationId$.pipe(
            switchMap(id => id !== null ? this.dp.getEvacuationWithReports(id) : of(null))
        ).subscribe(evacuation => {
            this.initChart(evacuation);
        });
        this.subs.add(sub);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private initChart(evacuation: Evacuation) {
        this.data = evacuation ? countStatusReports(evacuation) : null;
    }
}
