import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'evac-broadcast-message-dialog',
    templateUrl: './broadcast-message-dialog.component.html',
    styleUrls: ['./broadcast-message-dialog.component.scss']
})
export class BroadcastMessageDialogComponent implements OnInit {

    public message: string;

    constructor(private ref: DynamicDialogRef) { }

    ngOnInit(): void { }

    public sendMessage() {
        this.ref.close(this.message);
    }

    public cancel() {
        this.ref.close(null);
    }
}
