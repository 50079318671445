import { Component, OnInit } from '@angular/core';
import { Message } from '@app/core/models/evacuation';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { isToday } from '@app/core/util/stats-helper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface LocalMessage {
    message: Message;
    read: boolean;
    loading: boolean;
    error: Error;
}

@Component({
    selector: 'evac-unread-messages-dialog',
    templateUrl: './unread-messages-dialog.component.html',
    styleUrls: ['./unread-messages-dialog.component.scss']
})
export class UnreadMessagesDialogComponent implements OnInit {

    public messages: LocalMessage[];
    public isToday = isToday;

    constructor(private dp: DataProviderService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) { }

    ngOnInit(): void {
        this.messages = this.config.data.messages.map((message: Message): LocalMessage => ({
            message,
            read: false,
            loading: false,
            error: null,
        }));
    }

    public markAsRead(localMessage: LocalMessage) {
        const id = localMessage.message.id;
        localMessage.loading = true;
        this.dp.markAsReadMessage(id).subscribe(
            result => {
                if (result) {
                    localMessage.read = true;
                }
            },
            error => {
                console.error('error marking message read', error);
                localMessage.error = error;
            },
            () => {
                localMessage.loading = false;
            });
    }

    public close() {
        this.ref.close();
    }
}
