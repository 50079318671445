<p-card>
    <h4><i class="fas fa-border-all"></i> Zone Status</h4>
    <div class="text-center gsc-disabled-text">
        <ng-container *ngIf="!data">
            <p><i class="fal fa-dove fa-3x"></i></p>
            <p>There is no zone status data right now.</p>
        </ng-container>

        <p-table class="evac-zone-status-table" [value]="data" *ngIf="data">
        <ng-template pTemplate="body" let-zoneStatus>
            <tr>
                <td class="evac-zone-status-floor-column">
                    <p-tag [value]="zoneStatus.floor" [rounded]="true"></p-tag>
                </td>                       
                <td class="evac-zone-status-zones-column">
                    <p-tag *ngFor="let zone of zoneStatus.zones" [value]="zone.name" [severity]="zone.severity" [rounded]="true"></p-tag>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</p-card>
