<p-card>
    <h4><i class="fas fa-file-user"></i> Reports for {{ evacuation.building.name }}</h4>
    <p-table #reportTable class="evac-reports-table" [value]="reports"
        [rows]="5"
        [paginator]="true"
        [globalFilterFields]="['status','zone.floor','zone.name','user.firstName','user.lastName','comment']">
        <ng-template pTemplate="caption">
            <div class="form-group gsc-full-span">
                <label for="search">Search reports:</label>
                <input pInputText type="text" (input)="reportTable.filterGlobal($any($event.target).value, 'contains')" />
            </div>
        </ng-template>
        <ng-template pTemplate="body" let-report>
            <tr>
                <td class="evac-status-table-column">
                    <p-tag *ngIf="report.status?.toLocaleUpperCase() === 'OK'" value="OK" severity="success"></p-tag>
                    <p-tag *ngIf="report.status?.toLocaleUpperCase() === 'NOK'" value="NOK" severity="danger"></p-tag>
                </td>                       
                <td>
                    <i class="fal fa-map-marker-alt"></i> {{report?.zone?.floor}} {{report?.zone?.name}}<br>
                    <ng-container *ngIf="!isToday(report?.createdOn)">
                        <i class="fal fa-calendar"></i> {{report?.createdOn | date:'d MMM yyyy'}}<br>
                    </ng-container>
                    <i class="fal fa-clock"></i> {{report?.createdOn | date:'HH:mm'}}<br>
                </td>
                <td>
                    <i class="fal fa-user"></i> {{report?.user?.firstName}} {{report?.user?.lastName}}<br/>
                    <ng-container *ngIf="report?.comment"><i class="fal fa-comment"></i> {{report?.comment}}<br/></ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
