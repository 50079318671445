import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Evacuation } from '@app/core/models/evacuation';
import { Zone } from '@app/core/models/zone';
import { DataProviderService } from '@app/core/services/data-provider.service';
import { sortFloors } from '@app/core/util/data-helper';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'evac-zone-status',
    templateUrl: './zone-status.component.html',
    styleUrls: ['./zone-status.component.scss']
})
export class ZoneStatusComponent implements OnInit, OnDestroy {

    @Input()
    public set evacuationId(id: number) {
        this.evacuationId$.next(id);
    }

    public data: any = null;

    private evacuationId$ = new BehaviorSubject<number>(null);
    private subs = new Subscription();

    constructor(private dp: DataProviderService) { }

    ngOnInit(): void {
        const sub = this.evacuationId$.pipe(
            switchMap(id => id !== null ? this.dp.getEvacuationWithReports(id) : of(null as Evacuation))
        ).subscribe(evacuation => {
            this.calculateStatus(evacuation);
        });
        this.subs.add(sub);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private calculateStatus(evacuation: Evacuation) {
        if (evacuation === null) {
            this.data = null;
            return;
        }

        const reports = evacuation.reports.slice().sort((a, b) => a.id - b.id);
        const zones = new Map<number, Zone>(evacuation.building.zones.map(zone => ([zone.id, zone])));
        const zoneStatus = new Map<number, string>(reports
            .filter(report => report.zone !== null)
            .map(report => ([report.zone.id, report.status])));

        type FloorZoneList = Array<{ name: string; severity: string }>;
        const floorGrid = new Map<string, FloorZoneList>();
        const severityMap = {ok: 'success', nok: 'danger'};
        zones.forEach((zone, zoneId) => {
            const floorZones = floorGrid.get(zone.floor) || [];
            floorGrid.set(zone.floor, [...floorZones, {name: zone.name, severity: severityMap[(zoneStatus.get(zoneId) || 'unknown').toLocaleLowerCase()] || 'info'}]);
        });
        const floors = sortFloors([...floorGrid.keys()], evacuation.building.code);
        this.data = floors.map(floor => ({floor, zones: floorGrid.get(floor).slice().sort((a, b) => a.name.localeCompare(b.name))}));
    }

}
