import { Evacuation } from '../models/evacuation';
import { Zone } from '../models/zone';

export const countStatusReports = (evacuation: Evacuation): { success: number; danger: number; total: number } => {
    const reports = evacuation.reports;
    const zones = new Map<number, Zone>(evacuation.building.zones.map(zone => ([zone.id, zone])));
    const n = zones.size;

    const zoneStatus = new Map<number, string>(reports
        .filter(report => report.zone !== null)
        .map(report => ([report.zone.id, report.status.toLocaleUpperCase()]))
    );
    const statusCounts = new Map<string, number>();
    for (const status of zoneStatus.values()) {
        statusCounts.set(status, (statusCounts.get(status) || 0) + 1);
    }

    const ok = statusCounts.get('OK') || 0;
    const nok = statusCounts.get('NOK') || 0;

    return {
        success: ok,
        danger: nok,
        total: n,
    };
};

export const isToday = (someDate: Date): boolean => {
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
};
