<p-card>
    <h4><i class="fad fa-running"></i> Recent evacuations </h4>
    <p-table [value]="evacuations$ | async">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col>
                <col>
                <col style="width: 3em">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="body" let-evacuation>
            <tr>
                <td>
                    <b>{{ evacuation.building.name }}</b><br/>
                    {{ evacuation.reportCount }} reports<br/>
                    <ng-container *ngIf="countStats(evacuation) as data">
                        <p-badge [value]="'' + data.success" severity="success"></p-badge>
                        <p-badge [value]="'' + data.danger" severity="danger"></p-badge>
                        <p-badge [value]="'' + (data.total - data.success - data.danger)" severity="info"></p-badge>
                    </ng-container>
                </td>
                <td>
                    <i class="fal fa-calendar"></i> {{ evacuation.startTime | date:'d MMM yyyy' }}<br/>
                    <i class="fal fa-clock"></i> {{ evacuation.startTime | date:'HH:mm' }}<br/>
                    <i class="fal fa-stopwatch"></i> {{ deltaFormatted(evacuation.startTime, evacuation.endTime) }}
                </td>
                <td>
                    <button [disabled]="blocked" pButton type="button" icon="far fa-file-csv" (click)="downloadExport(evacuation.id)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <pre style="color: red;" *ngIf="error">
        ERROR:
        {{ error | json }}
    </pre>
</p-card>