<h4><i class="fad fa-siren-on"></i> Confirm message</h4>

<p>
    <i class="fal fa-building"></i>&nbsp;{{ evacEvent.evacuation?.building.name || '-' }}<br/>
    <i class="fal fa-user-shield"></i>&nbsp;{{ evacEvent.author }}<br/>
    <i class="fal fa-clock"></i>&nbsp;{{ eventTime | date:'HH:mm' }}<br/>
</p>

<p>
    <p-message severity="info" [text]="evacEvent.message"></p-message>
</p>

<div class="text-center">
    <button [loading]="loading" pButton type="button" icon="far fa-eye" iconPos="left" label="Ok" (click)="confirm()" class="p-button-success"></button>
</div>
