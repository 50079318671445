<div *ngIf="floors && !selectedFloor" class="text-center">
    <h3>Choose a floor:</h3>
    <button *ngFor="let floor of floors" (click)="selectFloor(floor)" pButton type="button" [label]="floor"></button><br/>
    <button pButton icon="far fa-times" class="p-button-danger" (click)="cancel()"></button>
</div>
<div *ngIf="zones" class="text-center">
    <h3>Choose a zone:</h3>
    <button *ngFor="let zone of zones" (click)="selectZone(zone)" pButton type="button" [label]="zone.name"></button><br/>
    <button pButton icon="far fa-times" class="p-button-danger" (click)="cancel()"></button>
</div>
