import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/core/models/user';
import { LoadingService } from '@app/core/services/loading.service';
import { environment } from '@app/environment';
import { DataProviderService } from '@core/services/data-provider.service';
import { Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';


@Component({
    selector: 'evac-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input()
    public showUser = true;

    public userInfo: User = null;
    public initials = '-';
    public envTag: string = environment.envTag || null;
    public online: boolean;
    public slow: boolean;
    public loading: boolean = false;
    public heartbeat: boolean = false;

    private subs: Subscription;

    constructor(private router: Router, private dp: DataProviderService, private ls: LoadingService) { }

    ngOnInit() {
        this.subs = new Subscription();

        this.subs.add(this.dp.online$.subscribe(status => {
            this.online = status;
        }));

        this.subs.add(this.dp.slow$.subscribe(status => {
            this.slow = status;
        }));

        this.dp.getAuthenticatedUser().pipe(take(1)).subscribe((user) => {
            // eslint-disable-next-line no-console
            console.debug('[header] set userinfo', user);
            this.userInfo = user;
            this.initials = user.firstName[0] + user.lastName[0];
        },
            (err: any) => {
                this.userInfo = null;
                this.initials = '-';
                console.error('Error during login status check', err);
                // this.dp.logout();
                this.router.navigate(['login']);
            });
        this.listenToLoading();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public logout() {
        this.dp.logout();
        this.router.navigate(['login']);
    }

    private listenToLoading(): void {
        this.ls.loading$
            .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .subscribe((loading) => {
                this.loading = loading;
            });
        this.ls.heartbeat$
            .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .subscribe((heartbeat) => {
                this.heartbeat = heartbeat;
            });
    }
}
