import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const HEARTBEAT_DURATION = 1500;
const SLOW_CONNECTION_DELAY = 10000;

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public heartbeat$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public slow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private count = 0;
    private heartbeatCount = 0;
    private activityCount = 0;

    constructor() { }

    public setLoading(loading: boolean): void {
        this.count += loading ? 1 : -1;
        if (loading && this.count === 1) {
            this.loading$.next(true);
        }
        if (!loading && this.count === 0) {
            this.loading$.next(false);
        }
    }

    public beat(): void {
        this.heartbeatCount += 1;
        if (this.heartbeatCount === 1) {
            this.heartbeat$.next(true);
        }
        setTimeout(() => {
            this.heartbeatCount -= 1;
            if (this.heartbeatCount === 0) {
                this.heartbeat$.next(false);
            }
        }, HEARTBEAT_DURATION);

        this.activityCount += 1;
        if (this.activityCount === 1) {
            this.slow$.next(false);
        }
        setTimeout(() => {
            this.activityCount -= 1;
            if (this.activityCount === 0) {
                this.slow$.next(true);
            }
        }, SLOW_CONNECTION_DELAY);
    }
}
