
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { LoadingService } from '../services/loading.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        private _loading: LoadingService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._loading.setLoading(true);
        let cancelled = true;
        // eslint-disable-next-line no-console
        console.debug('[HttpRequestInterceptor] intercept - request', request);
        return next.handle(request)
            .pipe(
                catchError((err) => {
                    // eslint-disable-next-line no-console
                    console.debug('[HttpRequestInterceptor] intercept - error', err);
                    this._loading.setLoading(false);
                    cancelled = false;
                    return throwError(err);
                }),
                tap((evt: HttpEvent<any>) => {
                    if (evt instanceof HttpResponse) {
                        // eslint-disable-next-line no-console
                        console.debug('[HttpRequestInterceptor] intercept - response', evt);
                        this._loading.setLoading(false);
                        cancelled = false;
                    }
                }),
                finalize(() => {
                    if (cancelled) {
                        // eslint-disable-next-line no-console
                        console.debug('[HttpRequestInterceptor] intercept - request cancelled', request);
                        this._loading.setLoading(false);
                    }
                })
            );
    }
}
