import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataProviderService, UserSearchInfo } from '@app/core/services/data-provider.service';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';

@Component({
    selector: 'evac-search-user',
    templateUrl: './search-user.component.html',
    styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit, OnDestroy {

    @Output()
    public emailChange = new EventEmitter<string>();

    @Output()
    public usersFound = new EventEmitter<UserSearchInfo>();

    public emailSuggestions: string[];

    @Input()
    set email(value: string) {
        this.email$.next(value);
        this.emailChange.emit(value);
    }

    get email(): string {
        return this.email$.value;
    }

    private email$ = new BehaviorSubject<string>('');
    private subs = new Subscription();

    constructor(private dp: DataProviderService) { }

    ngOnInit(): void {
        this.subs.add(this.email$.pipe(
            distinctUntilChanged(),
            debounceTime(200),
            switchMap(query => query ? this.dp.searchUsers(query.toLocaleLowerCase()) : of(null as UserSearchInfo))
        ).subscribe(
            (info) => {
                this.emailSuggestions = (info !== null) ? info.users.map(user => user.email) : [];
                this.usersFound.emit(info);
            }
        ));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
