<header class="gsc-header p-grid p-nogutter p-ai-center gsc-topbar-fixed">
  
    <div class="gsc-topbar-logo-section p-col-10 p-md-4">
        <div class="p-grid">
            <div class="p-col-4 p-md-3">  
                <img
                    src="assets/images/CEU_logo.svg"
                    alt="The logo of the Council of the EU"
                    title="The logo of the Council of the EU"
                />
            </div>
            <div class="p-col-8 p-md-9 p-as-center gsc-app-name">
                <h1>EVACUATE</h1>               
                <p class="subtitle1">of the GSC   
                    <p-tag *ngIf="envTag" severity="warning" [value]="envTag"></p-tag>
                    <ng-container *ngIf="userInfo">
                        &nbsp;<i [ngClass]="[heartbeat ? 'fas' : 'fal', 'fa-heartbeat', 'color-alert']"></i>
                        &nbsp;<i [ngClass]="[online ? (slow ? 'color-warning ' : 'color-success ') + (loading ? 'pi pi-spin pi-spinner' : 'fas fa-cloud') : 'color-alert fas fa-plug']"></i>
                    </ng-container>
                </p>
            </div>
        </div>
    </div>

    <div class="gsc-topbar-profile-section p-md-offset-6 p-col-2" *ngIf="showUser && userInfo">
        <p class="gsc-avatar"><span (click)="logout()">{{ initials }}</span></p>
        <p class="size7 no-sm">
            {{ userInfo.firstName }} {{ userInfo.lastName }}
            <a (click)="logout()" class="size8">
                <i class="fal fa-sign-out"></i>
                <span class="no-sm">log out</span>
            </a>
        </p>
    </div>

</header>