<evac-header [title]="'Login'" [showUser]="false"></evac-header>
<div class="gsc-view-public">
    <div class="gsc-single-panel vertical-center">

<ng-container *ngIf="token; then loggedIn; else loggedOut"></ng-container>

<ng-template #loggedOut>

    <ng-container *ngIf="!showNewPasswordFields; else newPasswordTemplate">

        <h2>Sign in</h2>
        <p>Please sign in using your existing credentials.</p>

        <p-message *ngIf="errorMessage" severity="error" [text]="errorMessage"></p-message>
        <form #signInForm="ngForm">
            <div class="form-group">
                <label for="email">Email</label>           
                <input id="email" [(ngModel)]="email" type="text" pInputText class="evac-longinput" name="email" required #emailInput="ngModel"> 
                <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="validation-error">
                    <div *ngIf="emailInput.errors?.required">
                        Email is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>           
                <input id="password" [(ngModel)]="password" type="password" pInputText class="evac-longinput" name="password" required minlength="4" #passwordInput="ngModel"> 
                <div *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)" class="validation-error">
                    <div *ngIf="passwordInput.errors?.required">
                        Password is required.
                    </div>
                </div>
            </div>
            <div class="evac-button-control">
                <p-button [disabled]="signInForm.form.invalid" label="Sign in" (click)="login()"></p-button>
                <a (click)="showNewPasswordFields = true">I wish to change my password</a>
            </div>
        </form>
    </ng-container>

    <ng-template #newPasswordTemplate>

        <h2>Set new password</h2>
        <p>Please set a new password.</p>

        <p-message *ngIf="errorMessage" severity="error" [text]="errorMessage"></p-message>

        <form #newPasswordForm="ngForm" evacPasswordMatch>

            <div class="form-group">
                <label for="email">Email</label>           
                <input id="email" [(ngModel)]="email" type="text" pInputText class="evac-longinput" name="email" required #emailInput="ngModel">
                <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="validation-error">
                    <div *ngIf="emailInput.errors?.required">
                        Email is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="oldPassword">Old password or reset key</label>           
                <input id="oldPassword" [(ngModel)]="password" type="password" pInputText class="evac-longinput" name="oldPassword" required minlength="4" #oldPasswordInput="ngModel"> 
                <div *ngIf="oldPasswordInput.invalid && (oldPasswordInput.dirty || oldPasswordInput.touched)" class="validation-error">
                    <div *ngIf="oldPasswordInput.errors?.required">
                        Old password or reset key is required.
                    </div>
                    <div *ngIf="oldPasswordInput.errors?.minlength">
                        Old password must be at least 4 characters long.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="newPassword">New password</label>           
                <input id="newPassword" [(ngModel)]="newPassword" type="password" pInputText class="evac-longinput" name="newPassword" required minlength="8" #newPasswordInput="ngModel" [pattern]="oneAlphaNumPattern">
                <div *ngIf="newPasswordInput.invalid && (newPasswordInput.dirty || newPasswordInput.touched)" class="validation-error">
                    <div *ngIf="newPasswordInput.errors?.required">
                        New password is required.
                    </div>
                    <div *ngIf="newPasswordInput.errors?.minlength">
                        New password must be at least 8 characters long.
                    </div>
                    <div *ngIf="newPasswordInput.errors?.pattern?.requiredPattern">
                        New password must contain at least one number and one letter.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="newPasswordAgain">New password again</label>           
                <input id="newPasswordAgain" [(ngModel)]="newPasswordAgain" type="password" pInputText class="evac-longinput" name="newPasswordAgain" required #newPasswordAgainInput="ngModel"> 
                <div *ngIf="newPasswordAgainInput.invalid && (newPasswordAgainInput.dirty || newPasswordAgainInput.touched)" class="validation-error">
                    <div *ngIf="newPasswordAgainInput.errors?.required">
                        Re-type new password.
                    </div>
                </div>
            </div>

            <div *ngIf="newPasswordForm.form.invalid && (newPasswordForm.form.dirty || newPasswordForm.form.touched)" class="validation-error">
                <div *ngIf="newPasswordForm?.form?.errors?.noPasswordMatch">
                    Passwords don't match.
                </div>
            </div>
    
            <div class="evac-button-control">
                <p-button [disabled]="newPasswordForm.form.invalid" label="Change password" (click)="changePassword()"></p-button>
                <a (click)="showNewPasswordFields = false">I wish to sign in</a>
            </div>

        </form>
    </ng-template>

</ng-template>

<ng-template #loggedIn>
    Logged in as {{tokenUser && tokenUser.firstName}} {{tokenUser && tokenUser.lastName}}<br/>
    Redirecting now...
</ng-template>

<p-inplace  *ngIf="error">
    <ng-template pTemplate="display">
        <p-tag value="Show error details" icon="fas fa-exclamation-triangle" severity="danger"></p-tag>
    </ng-template>
    <ng-template pTemplate="content">
        <pre style="color: red;">
            ERROR:
            {{ error | json }}
        </pre>
    </ng-template>
</p-inplace>

</div>

</div>
<evac-footer></evac-footer>
