<evac-header [title]="'Report'"></evac-header>
<div class="gsc-view-public">
    <p-messages></p-messages>

    <div class="gsc-2column-grid">       
        <div class="role-select" *ngIf="debugMode">
            <h4><i class="fad fa-bug"></i> Toggle roles</h4>
            <p-selectButton [options]="authUserRoles" [(ngModel)]="selectedAuthUserRoles" optionLabel="name" [multiple]="true"></p-selectButton>
        </div>
    
        <p-card>
            <h4><i class="fad fa-running"></i> Active evacuations ({{ activeEvacuations.length }})</h4>
            <div class="text-center gsc-disabled-text" *ngIf="activeEvacuations.length === 0">
                <p><i class="fal fa-dove fa-3x"></i></p>
                <p>There is no evacuation right now.</p>
            </div>
            
            <p-tabMenu *ngIf="evacMenu && evacMenu.length > 1" [model]="evacMenu" [activeItem]="evacMenuActive"></p-tabMenu>

            <div class="evac-3column-grid" *ngIf="selectedEvacuation">
                <div>
                    <p-tag severity="danger">LIVE</p-tag>
                    <div *ngIf="selectedEvacuationMessages.length > 0" class="unread-msgs" (click)="showUnreadMessagesDialog(selectedEvacuationMessages)">
                        <i class="far fa-inbox fa-2x" pBadge [value]="'' + selectedEvacuationMessages.length" severity="danger"></i>
                    </div>
                </div>
                <div>
                    <h5> {{ selectedEvacuation.building.name | uppercase }} </h5>
                    <p>
                        <ng-container *ngIf="!isToday(selectedEvacuation.startTime)">
                            <i class="fal fa-calendar"></i> {{ selectedEvacuation.startTime | date:'d MMM yyyy' }}<br/>
                        </ng-container>
                        <i class="fal fa-clock"></i> {{ selectedEvacuation.startTime | date:'HH:mm' }}<br/>
                        <ng-container *ngIf="selectedEvacuation.createdByUser">
                            <i class="fal fa-user-shield"></i> {{ selectedEvacuation.createdByUser.firstName }} {{ selectedEvacuation.createdByUser.lastName }}<br/>
                        </ng-container>
                    </p>
                </div>
                <ul class="evac-counter" *ngIf="calculateEvacuationDuration(selectedEvacuation) as delta">
                    <li><span>{{ delta.hours }}</span> <span>Hours</span></li>
                    <li><span>{{ delta.mins | number:'2.0-0' }}</span> <span>Minutes</span></li>
                    <li><span>{{ delta.secs | number:'2.0-0' }}</span> <span>Seconds</span></li>
                </ul>
            </div>
            <div *ngIf="(hasAdminRole() || hasStatisticRole()) && selectedEvacuation">
                Zone status progress:
                <evac-statistics [evacuationId]="selectedEvacuation.id"></evac-statistics>
            </div>
        </p-card>

        <p-card *ngIf="(hasAdminRole() || hasStewardRole()) && selectedEvacuation">
            <h4><i class="fas fa-bullhorn"></i> Reporting <i *ngIf="reportQueue.length > 0" class="fal fa-file-alt" pBadge [value]="'' + reportQueue.length" severity="danger"></i></h4>
            <p-tabView>

                <p-tabPanel header="Myself">
                    <ng-container *ngIf="reportAgain || !myReport; else alreadyReported">
                        <h3 class="text-center evac-headline">{{authUser?.firstName}} {{authUser?.lastName}}</h3>

                        <div class="text-center form-group">
                            Reports for zone:<br/>
                            <p-chip (click)="showSelectZoneDialog(false)">{{mySelectedZone?.floor || "-"}} {{mySelectedZone?.name}}</p-chip>
                        </div>
        
                        <div class="form-group gsc-full-span">
                            <label for="MyComment">Comment:</label>
                            <input id="MyComment" type="text" pInputText [(ngModel)]="myComment" style="max-width: 97.5%"/>
                        </div>
    
                        <div class="evac-2column-grid">
                            <button [disabled]="!mySelectedZone" pButton type="button" icon="far fa-check" iconPos="left" label="OK" (click)="sendMyOKReport()" class="p-button-success"></button>
                            <button [disabled]="!mySelectedZone" pButton type="button" icon="far fa-exclamation-triangle" iconPos="left" label="NOK" (click)="sendMyNOKReport()" class="p-button-danger"></button>
                        </div>
                    </ng-container>

                    <ng-template #alreadyReported>
                        <p class="text-center">My last report was sent at <br/>
                            <b>{{ myReport.lastmodifiedOn | date:'HH:mm' }}</b> on <b>{{ myReport.lastmodifiedOn | date:'d MMM yyyy' }}</b><br/>
                        with status <br/>
                            <p-tag *ngIf="myReport.status?.toLocaleUpperCase() === 'OK'" value="OK" severity="success"></p-tag>
                            <p-tag *ngIf="myReport.status?.toLocaleUpperCase() === 'NOK'" value="NOK" severity="danger"></p-tag>
                            <br/>
                            for zone <b>{{ myReport.zone?.floor }} {{ myReport.zone?.name }}</b><br/>
                            <ng-container *ngIf="myReport.comment"><i class="fal fa-comment"></i> {{myReport.comment}}<br/></ng-container>
                        </p>
                       
                        <p class="text-center evac-btn-reportagain">
                            <button pButton type="button" icon="fas fa-bullhorn" iconPos="left" label="I want to report again" (click)="reportAgain = true"></button>
                        </p>
                    </ng-template>
                </p-tabPanel>

                <p-tabPanel header="Another steward">
                    <ng-container *ngIf="online && !slow; else noReporting">
                        <div class="gsc-3column-grid text-center">
                            <button pButton type="button" icon="far fa-barcode-read" iconPos="left" label="Scan" (click)="showScanDialog()"></button>
                            <h5> OR </h5>
                            <div class="form-group">
                                <label for="Email address">Type email address <a *ngIf="email" (click)="email = ''">[ clear ]</a></label>
                                <evac-search-user [(email)]="email" (usersFound)="setUsers($event)"></evac-search-user>
                            </div>
                        </div>

                        <ng-container *ngIf="!userFound && email">
                            <h3 class="text-center evac-headline" *ngIf="users?.length === 0">No matching user found.</h3>
                            <h3 class="text-center evac-headline" *ngIf="users?.length > 1">{{ userCount }} matching users found. Please refine.</h3>
                        </ng-container>

                        <ng-container *ngIf="userFound">
                            <ng-container *ngIf="reportOtherAgain || !otherReport; else alreadyReportedOther">
                                <h3 class="text-center evac-headline" *ngIf="userFound">{{user.firstName}} {{user.lastName}}</h3>

                                <div class="text-center form-group">
                                    Reports for zone:<br/>
                                    <p-chip (click)="showSelectZoneDialog(true)">{{selectedZone?.floor || "-"}} {{selectedZone?.name}}</p-chip>
                                </div>
                
                                <div class="form-group gsc-full-span">
                                    <label for="Comment">Comment:</label>
                                    <input type="text" pInputText [(ngModel)]="comment" style="max-width: 97.5%"/>
                                </div>

                                <div class="evac-2column-grid">
                                    <button [disabled]="!selectedZone" pButton type="button" icon="far fa-check" iconPos="left" label="OK" (click)="sendOtherOKReport()" class="p-button-success"></button>
                                    <button [disabled]="!selectedZone" pButton type="button" icon="far fa-exclamation-triangle" iconPos="left" label="NOK" (click)="sendOtherNOKReport()" class="p-button-danger"></button>
                                </div>
                            </ng-container>

                            <ng-template #alreadyReportedOther>
                                <p class="text-center">Latest report of <b>{{user.firstName}} {{user.lastName}}</b> was sent at <br/>
                                    <b>{{ otherReport.lastmodifiedOn | date:'HH:mm' }}</b> on <b>{{ otherReport.lastmodifiedOn | date:'d MMM yyyy' }}</b><br/>
                                with status <br/>
                                    <p-tag *ngIf="otherReport.status?.toLocaleUpperCase() === 'OK'" value="OK" severity="success"></p-tag>
                                    <p-tag *ngIf="otherReport.status?.toLocaleUpperCase() === 'NOK'" value="NOK" severity="danger"></p-tag>
                                    <br/>
                                    for zone <b>{{ otherReport.zone?.floor }} {{ otherReport.zone?.name }}</b><br/>
                                    <ng-container *ngIf="otherReport.comment"><i class="fal fa-comment"></i> {{otherReport.comment}}<br/></ng-container>
                                </p>
                            
                                <p class="text-center evac-btn-reportagain">
                                    <button pButton type="button" icon="fas fa-bullhorn" iconPos="left" label="Report again" (click)="reportOtherAgain = true"></button>
                                </p>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                    <ng-template #noReporting>
                        <div class="text-center gsc-disabled-text">
                            <p><i class="fal fa-plug fa-3x"></i></p>
                            <p>
                                This functionality is disabled due to no connectivity.
                            </p>
                        </div>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </p-card>

        <evac-report-list *ngIf="(hasAdminRole() || hasStatisticRole()) && selectedEvacuation" [evacuation]="selectedEvacuation" [reports]="reports"></evac-report-list>

        <evac-zone-status *ngIf="(hasAdminRole() || hasStatisticRole()) && selectedEvacuation" [evacuationId]="selectedEvacuation.id"></evac-zone-status>

        <evac-view-evacuations [blocked]="!online || slow" *ngIf="hasAdminRole() || hasStatisticRole()"></evac-view-evacuations>

        <evac-manage-evacuations [blocked]="!online || slow" *ngIf="hasAdminRole()"></evac-manage-evacuations>

        <evac-admin-reset-password [blocked]="!online || slow" *ngIf="hasAdminRole()"></evac-admin-reset-password>
    </div>
</div>

<p-inplace  *ngIf="error">
    <ng-template pTemplate="display">
        <p-tag value="Show error details" icon="fas fa-exclamation-triangle" severity="danger"></p-tag>
    </ng-template>
    <ng-template pTemplate="content">
        <pre style="color: red;">
            ERROR:
            {{ error | json }}
        </pre>
    </ng-template>
</p-inplace>

<evac-footer></evac-footer>
