import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';

import { DataProviderService } from '../services/data-provider.service';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private dp: DataProviderService) { }

    public canActivate(_route: ActivatedRouteSnapshot): boolean {

        const secure = this.dp.isAuthenticated();

        if (!secure) {
            alert('Access Denied');
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}
