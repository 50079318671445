<p-blockUI [blocked]="blocked" [target]="pcard">
    <i class="fas fa-plug" style="color: red; font-size: 3rem"></i>
</p-blockUI>

<p-card #pcard>
    <h4><i class="fas fa-key"></i> Reset User Password</h4>

    <p-message *ngIf="errorMessage" severity="error" [text]="errorMessage"></p-message>
    <ng-container *ngIf="!resetDone; else showResetkey">
        <div class="text-center">
            <div class="form-group">
                <label for="email">Email</label>
                <evac-search-user [(email)]="email" (usersFound)="setUsers($event)"></evac-search-user>
            </div>
            <h3 class="text-center evac-headline" *ngIf="userFound">Reset password for {{user.firstName}} {{user.lastName}}</h3>
            <button pButton type="button" [disabled]="!userFound" label="Reset password"
                (click)="resetPassword()" class="p-button-danger"></button>
        </div>
    </ng-container>

    <ng-template #showResetkey>
        <p class="text-center">
            Password has been reset for user:<br />
            <b>{{ email }}</b><br />
            Reset key:<br />
            <span class="key-value">{{ resetKey }}</span>
            &nbsp;<button (click)="copyKeyToClipboard()" pButton type="button" icon="fas fa-copy"></button><br />
            <span *ngIf="copied" class="key-copied">Key copied!</span><br />
            Please send the above key to the user to allow him to set his own password.<br />
            <a (click)="clearForm()">Reset other user</a>
        </p>
    </ng-template>

    <pre style="color: red;" *ngIf="error">
        ERROR:
        {{ error | json }}
    </pre>
</p-card>