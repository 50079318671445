import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { LoginGuard } from './guards/login.guard';
import { SampleSkeletonGuard } from './guards/sample.guard-service';
import { AuthErrorInterceptor } from './interceptors/auth-error.interceptor';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { DataProviderService } from './services/data-provider.service';
import { LoadingService } from './services/loading.service';

@NgModule({
    providers: [

        /* add your services here */
        SampleSkeletonGuard,
        LoginGuard,
        DataProviderService,
        LoadingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        }
    ]
})
export class CoreModule { }
