import { Component, ViewEncapsulation } from '@angular/core';
@Component({
    moduleId: module.id,
    encapsulation: ViewEncapsulation.None,
    selector: 'evac-app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor() { }
}
